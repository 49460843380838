import type { NextPage } from 'next';
import { XNavLink } from 'shared/x-components/x-nav-link';
import Head from 'next/head';
import { Box, Button, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from 'shared/hooks/use-theme';
import { XSavvyLogo } from 'shared/x-components/x-savvy-logo';

const NotFound: NextPage = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Head>
        <title>Error: Not Found | Savvy</title>
      </Head>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.default',
          display: 'flex',
          flexGrow: 1,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
            404: We can’t find the page you’re looking for
          </Typography>
          <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2"></Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <XNavLink href="/" webOnlyPassHref>
              <Button component="a" variant="outlined">
                Back to Home
              </Button>
            </XNavLink>
          </Box>
        </Container>
      </Box>
      <Grid
        container
        sx={{
          position: 'absolute',
          bottom: 32,
          width: '100%',
        }}
        justifyContent="center"
      >
        <Box
          sx={{
            width: 92,
          }}
        >
          <XSavvyLogo fill={theme.palette.neutral[200]} />
        </Box>
      </Grid>
    </>
  );
};

export default NotFound;
