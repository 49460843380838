import NextLink from 'next/link';
import { styled } from '@mui/material/styles';
import { XNavLinkProps } from './types';

const StyledNavLink = styled(NextLink)(() => ({ textDecoration: 'none' }));

export const XNavLink = function ({ children, webOnlyPassHref, ...props }: XNavLinkProps) {
  return (
    <StyledNavLink passHref={webOnlyPassHref} {...props}>
      {children}
    </StyledNavLink>
  );
};
